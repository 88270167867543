import {Component, EventEmitter, Input, OnChanges, Output} from '@angular/core';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {News} from '../models/news';

@Component({
  selector: 'app-edit-nieuws',
  templateUrl: './edit-nieuws.component.html',
  styleUrls: ['./edit-nieuws.component.css']
})
export class EditNieuwsComponent implements OnChanges {

  @Input() news: News;
  @Output() update = new EventEmitter<News>();

  form: FormGroup;

  constructor(private fb: FormBuilder) {
    this.form = this.fb.group({
      id: new FormControl(null),
      title: new FormControl(null, Validators.required),
      date: new FormControl(null, Validators.required),
      body: new FormControl(null, Validators.required),
      bron: new FormControl(null),
      headerImage: new FormControl(null),
      bodyImage: new FormControl(null),
    });
  }

  ngOnChanges() {
    this.form.patchValue({...this.news});
  }

  saveForm() {
    this.update.emit(this.form.value);
  }
}

import {Component, EventEmitter, Input, OnChanges, Output} from '@angular/core';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {Evenement} from '../models/evenement';

@Component({
  selector: 'app-add-evenement',
  templateUrl: './add-evenement.component.html',
  styleUrls: ['./add-evenement.component.css']
})
export class AddEvenementComponent implements OnChanges {

  @Input() event: Evenement;
  @Output() update = new EventEmitter<Evenement>();

  form: FormGroup;

  constructor(private fb: FormBuilder) {
    this.form = this.fb.group({
      id: new FormControl(null),
      name: new FormControl('', Validators.required),
      location: new FormControl('', Validators.required),
      city: new FormControl('', Validators.required),
      date: new FormControl('', Validators.required),
      time: new FormControl('', Validators.required),
      visible: new FormControl(false, Validators.required),
      limit: new FormControl(0, Validators.required),
      facebook: new FormControl(null),
      aanwezig: new FormControl([]),
      afwezig: new FormControl([])
    });
  }

  ngOnChanges() {
    this.form.patchValue({...this.event});
  }

  saveForm() {
    this.update.emit(this.form.value);
  }

  resetAanwezigheid() {
    this.form.value.aanwezig = [];
    this.form.value.afwezig = [];
  }
}

import {Component} from '@angular/core';
import {AngularFirestore} from '@angular/fire/firestore';
import {map} from 'rxjs/operators';
import {FirebaseError} from 'firebase';
import {Evenement} from '../models/evenement';
import {Observable} from 'rxjs';

@Component({
  selector: 'app-event',
  templateUrl: './event.component.html',
  styleUrls: ['./event.component.css']
})
export class EventComponent {

  selected: Evenement;

  $upcoming: Observable<Evenement[]> = this.db.collection('events').valueChanges({idField: 'id'}).pipe(
    map(event => event
      .filter((e: Evenement) => new Date(e.date) >= new Date())
      .sort((a: any, b: any) => a.date.localeCompare(b.date))
    )
  ) as Observable<Evenement[]>;

  $done: Observable<Evenement[]> = this.db.collection('events').valueChanges({idField: 'id'}).pipe(
    map(event => event
      .filter((e: Evenement) => new Date(e.date) < new Date())
      .sort((a: any, b: any) => b.date.localeCompare(a.date))
    )
  ) as Observable<Evenement[]>;

  constructor(private db: AngularFirestore) {

  }

  remove(event: Evenement) {
    this.db.collection('events')
      .doc(event.id)
      .delete()
      .catch((error: FirebaseError) => alert(error.message));
  }

  update(event: Evenement) {
    if (event.id) {
      this.db.collection('events')
        .doc(event.id)
        .update({...event})
        .catch((error: FirebaseError) => alert(error.message));
    } else {
      this.db.collection('events')
        .add(event)
        .catch((error: FirebaseError) => alert(error.message));
    }
  }
}

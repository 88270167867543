<div class="container">
  <div class="row pb-3">
    <h2>Documenten</h2>
    <table class="table table-striped table-hover table-condensed" aria-hidden="true">
      <tr>
        <th id="tijd">Tijd</th>
        <th id="naam">Naam</th>
        <th id="extensie">Extensie</th>
        <th id="grootte">Grootte</th>
        <th id="acties">Acties</th>
      </tr>
      <tr *ngFor="let document of $documents | async">
        <td>{{document.upload | date:'dd/MM/yyyy HH:mm'}}</td>
        <td>{{document.name}}</td>
        <td>{{document.extension}}</td>
        <td>{{document.size | filesize}}</td>
        <td>
          <a [href]="document.url" target="_blank" class="btn btn-sm btn-outline-primary">
            <img src="../../icons/eye.svg" alt="" width="16" height="16" title="eye">
          </a>
          &nbsp;
          <button type="button" class="btn btn-sm btn-outline-danger" (click)="remove('documenten', document)">
            <img src="../../icons/trash.svg" alt="" width="16" height="16" title="trash">
          </button>
        </td>
      </tr>
    </table>

    <button type="button" class="btn btn-primary pull-right" data-toggle="modal" data-target="#upload">
      Bestand toevoegen
    </button>
  </div>

  <div class="row pb-3">
    <h2>Muziek</h2>
    <table class="table table-striped table-hover table-condensed" aria-hidden="true">
      <tr>
        <th id="muziek-tijd">Tijd</th>
        <th id="muziek-concert">Concert</th>
        <th id="muziek-groep">Groep</th>
        <th id="muziek-naam">Naam</th>
        <th id="muziek-extensie">Extensie</th>
        <th id="muziek-grootte">Grootte</th>
        <th id="muziek-audio">Audio</th>
        <th id="muziek-acties">Acties</th>
      </tr>
      <tr *ngFor="let music of $music | async">
        <td>{{music.upload | date:'dd/MM/yyyy HH:mm'}}</td>
        <td>{{music.concert}}</td>
        <td>{{music.group}}</td>
        <td>{{music.name}}</td>
        <td>{{music.extension}}</td>
        <td>{{music.size}}</td>
        <td>
          <audio controls class="pull-right">
            <source [src]="music?.url" type="audio/mp3">
          </audio>
        </td>
        <td>
          <button type="button" class="btn btn-sm btn-outline-danger" (click)="remove('muziekstukken', music)">
            <img src="../../icons/trash.svg" alt="" width="16" height="16" title="trash">
          </button>
        </td>
      </tr>
    </table>

    <button type="button" class="btn btn-primary pull-right" data-toggle="modal" data-target="#upload">
      Bestand toevoegen
    </button>
  </div>

  <div class="row pb-3">
    <h2>Opnames</h2>
    <table class="table table-striped table-hover table-condensed" aria-hidden="true">
      <tr>
        <th id="opnames-tijd">Tijd</th>
        <th id="opnames-concert">Concert</th>
        <th id="opnames-groep">Groep</th>
        <th id="opnames-naam">Naam</th>
        <th id="opnames-extensie">Extensie</th>
        <th id="opnames-grootte">Grootte</th>
        <th id="opnames-audio">Audio</th>
        <th id="opnames-acties">Acties</th>
      </tr>
      <tr *ngFor="let opname of $opnames | async">
        <td>{{opname.upload | date:'dd/MM/yyyy HH:mm'}}</td>
        <td>{{opname.concert}}</td>
        <td>{{opname.group}}</td>
        <td>{{opname.name}}</td>
        <td>{{opname.extension}}</td>
        <td>{{opname.size}}</td>
        <td>
          <audio controls class="pull-right">
            <source [src]="opname?.url" type="audio/mp3">
          </audio>
        </td>
        <td>
          <button type="button" class="btn btn-sm btn-outline-danger" (click)="remove('opnames', opname)">
            <img src="../../icons/trash.svg" alt="" width="16" height="16" title="trash">
          </button>
        </td>
      </tr>
    </table>

    <button type="button" class="btn btn-primary pull-right" data-toggle="modal" data-target="#upload">
      Bestand toevoegen
    </button>
  </div>
</div>

<app-upload-form></app-upload-form>

<form [formGroup]="form">
  <div formArrayName="groepen">
    <div *ngFor="let groep of form.get('groepen')?.controls; let i = index;">
      <h3>{{groep.value.name}}</h3>
      <div [formGroupName]="i">
        <div formArrayName="instruments">
          <div *ngFor="let instrument of groep.get('instruments')?.controls; let j = index;">
            <strong>{{instrument.value.name}}</strong>
            <div [formGroupName]="j">
              <tag-input formControlName="leden" [modelAsStrings]="true"
                         (onAdd)="save()" (onRemove)="save()"></tag-input>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</form>

import {Component} from '@angular/core';
import {combineLatest} from 'rxjs';
import {Evenement} from '../models/evenement';
import {map, switchMap} from 'rxjs/operators';
import {AngularFirestore} from '@angular/fire/firestore';
import {Profiel} from '../models/profiel';

@Component({
  selector: 'app-aanwezigheid',
  templateUrl: './aanwezigheid.component.html',
  styleUrls: ['./aanwezigheid.component.css']
})
export class AanwezigheidComponent {

  /* $upcoming: Observable<Evenement[]> = this.db.collection('events').valueChanges({idField: 'id'}).pipe(
     map(event => event
       .filter((e: Evenement) => new Date(e.date) >= new Date())
       .sort((a: any, b: any) => a.date.localeCompare(b.date))
     ),
   ) as Observable<Evenement[]>;*/


  $upcoming = this.db.collection('events').valueChanges({idField: 'id'}).pipe(
    map(event => event
      .filter((e: Evenement) => new Date(e.date) >= new Date())
      .sort((a: any, b: any) => a.date.localeCompare(b.date))
    ),
    switchMap((events: Evenement[]) => {
      const res = events.map((e: Evenement) => {
        return this.db.collection(`profiel`).valueChanges({idField: 'id'}).pipe(
          map(profiel => profiel
            .filter((p: Profiel) => e.aanwezig ? e.aanwezig.includes(p.id) : false)
            .sort((a: Profiel, b: Profiel) => a.familienaam.localeCompare(b.familienaam))
          ),
          map(profielen => Object.assign(e, {profielen}))
        );
      });
      return combineLatest(...res);
    })
  );

  constructor(private db: AngularFirestore) {

  }
}

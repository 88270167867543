import {BrowserModule} from '@angular/platform-browser';
import {NgModule} from '@angular/core';

import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {environment} from '../environments/environment';
import {AngularFireModule} from '@angular/fire';
import {AngularFirestoreModule} from '@angular/fire/firestore';
import {NieuwsComponent} from './nieuws/nieuws.component';
import {EditNieuwsComponent} from './edit-nieuws/edit-nieuws.component';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {WebsiteSettingsComponent} from './website-settings/website-settings.component';
import {AfficeFormComponent} from './affice-form/affice-form.component';
import {SlideshowFormComponent} from './slideshow-form/slideshow-form.component';
import {FotosComponent} from './fotos/fotos.component';
import {EditAlbumComponent} from './edit-album/edit-album.component';
import {LedenComponent} from './leden/leden.component';
import {TagInputModule} from 'ngx-chips';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {DocumentenComponent} from './documenten/documenten.component';
import {AngularFireStorageModule} from '@angular/fire/storage';
import {UploadFormComponent} from './upload-form/upload-form.component';
import {AngularFireAuthModule} from '@angular/fire/auth';
import {AngularFireAuthGuardModule} from '@angular/fire/auth-guard';
import {LoginComponent} from './login/login.component';
import {NgxFilesizeModule} from 'ngx-filesize';
import {EventComponent} from './event/event.component';
import {AddEvenementComponent} from './add-evenement/add-evenement.component';
import {AanwezigheidComponent} from './aanwezigheid/aanwezigheid.component';

@NgModule({
  declarations: [
    AppComponent,
    NieuwsComponent,
    EditNieuwsComponent,
    WebsiteSettingsComponent,
    AfficeFormComponent,
    SlideshowFormComponent,
    FotosComponent,
    EditAlbumComponent,
    LedenComponent,
    LoginComponent,
    EventComponent,
    AddEvenementComponent,
    DocumentenComponent,
    UploadFormComponent,
    AanwezigheidComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    AngularFireModule.initializeApp(environment.firebase),
    AngularFirestoreModule,
    AngularFireAuthModule,
    AngularFireAuthGuardModule,
    AngularFireStorageModule,
    ReactiveFormsModule,
    FormsModule,
    TagInputModule,
    NgxFilesizeModule
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule {
}

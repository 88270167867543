import {Component, EventEmitter, Input, OnChanges, Output} from '@angular/core';
import {Foto} from '../models/foto';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';

@Component({
  selector: 'app-edit-album',
  templateUrl: './edit-album.component.html',
  styleUrls: ['./edit-album.component.css']
})
export class EditAlbumComponent implements OnChanges {

  @Input() foto: Foto;
  @Output() update = new EventEmitter<Foto>();

  form: FormGroup;

  constructor(private fb: FormBuilder) {
    this.form = this.fb.group({
      id: new FormControl(null),
      name: new FormControl(null, Validators.required),
      date: new FormControl(null, Validators.required),
      url: new FormControl(null, Validators.required),
      image: new FormControl(null),
      showOnWebsite: new FormControl(false, Validators.required),
    });
  }

  ngOnChanges() {
    this.form.patchValue({...this.foto});
  }

  saveForm() {
    this.update.emit(this.form.value);
    this.form.reset({});
  }
}

<div class="row">
  <div class="col">
    <h2>Affice</h2>
    <app-affice-form [$affice]="$affice" (update)="updateAffice($event)"></app-affice-form>
  </div>
</div>

<hr/>

<div class="row">
  <div class="col">
    <h2>Slideshow</h2>
    <app-slideshow-form [$slideshow]="$slideshow" (update)="updateSlideshow($event)"></app-slideshow-form>
  </div>
</div>

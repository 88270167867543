import {Component} from '@angular/core';
import {AngularFirestore} from '@angular/fire/firestore';
import {AngularFireStorage} from '@angular/fire/storage';
import {Observable} from 'rxjs';
import {Bestand} from '../models/bestand';
import {FirebaseError} from 'firebase';

@Component({
  selector: 'app-documenten',
  templateUrl: './documenten.component.html',
  styleUrls: ['./documenten.component.css']
})
export class DocumentenComponent {

  $music: Observable<Bestand[]> = this.db.collection('muziekstukken').valueChanges({idField: 'id'}) as Observable<Bestand[]>;

  $opnames: Observable<Bestand[]> = this.db.collection('opnames').valueChanges({idField: 'id'}) as Observable<Bestand[]>;

  $documents: Observable<Bestand[]> = this.db.collection('documenten').valueChanges({idField: 'id'}) as Observable<Bestand[]>;

  constructor(private db: AngularFirestore, private storage: AngularFireStorage) {

  }

  remove(collection: string, file: Bestand) {
    this.storage.ref(`${collection}/${file.name}.${file.extension}`).delete().subscribe(
      () => this.db.collection(collection)
        .doc(file.id)
        .delete()
        .catch((error: FirebaseError) => alert(error.message)),
      (error: FirebaseError) => alert(error.message));
  }
}

<div class="modal fade" tabindex="-1" role="dialog" id="upload">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <form class="form-horizontal" [formGroup]="form" (ngSubmit)="addFile(form.getRawValue())">
        <div class="modal-header">
          <h4 class="modal-title">Bestand toevoegen</h4>
          <button type="button" class="close" data-dismiss="modal" aria-label="Close"><span
            aria-hidden="true">&times;</span></button>
        </div>
        <div class="modal-body">
          <div class="form-group">
            <label for="naam">Bestandsnaam</label>
            <input type="text" formControlName="name" class="form-control" id="naam" placeholder="Bestandsnaam">
          </div>
          <div class="form-group">
            <label for="location">Locatie</label>
            <select formControlName="location" class="form-control" id="location">
              <option value="documenten">Document</option>
              <option value="muziekstukken">Muziek</option>
              <option value="opnames">Opname</option>
            </select>
          </div>
          <div class="form-group" *ngIf="form.get('location').value !== 'documenten'">
            <label for="location">Concert</label>
            <input type="text" formControlName="concert" class="form-control" id="concert" placeholder="Concert">
          </div>
          <div class="form-group" *ngIf="form.get('location').value !== 'documenten'">
            <label for="group">Groep</label>
            <select formControlName="group" class="form-control" id="group">
              <option value="drumband">Drumband</option>
              <option value="harmonie">Harmonie</option>
              <option value="jeugdorkest">Jeugdorkest</option>
            </select>
          </div>
          <div class="form-group">
            <label for="file">Bestand</label>
            <input type="file" (change)="upload($event)" class="form-control" id="file" placeholder="Bestand">
          </div>
        </div>
        <div class="modal-footer">
          <span *ngIf="(uploadPercent | async) > 0">Uploading: {{(uploadPercent | async) | number : '1.1-2'}}%</span>
          <button type="button" class="btn btn-default" data-dismiss="modal">Sluiten</button>
          <button type="submit" class="btn btn-primary" [disabled]="form.invalid">Opslaan</button>
        </div>
      </form>
    </div>
  </div>
</div>

<div class="row">
  <div class="col-9">
    <form [formGroup]="form">
      <div class="form-group">
        <label for="url">URL</label>
        <input type="url" formControlName="url" class="form-control" id="url" placeholder="http...">
      </div>
      <div class="form-group">
        <label for="naam">Naam</label>
        <input type="text" formControlName="name" class="form-control" id="naam" placeholder="naam">
      </div>
      <div class="form-check">
        <input type="checkbox" formControlName="enabled" class="form-check-input" id="enable">
        <label for="enable" class="form-check-label">Aanzetten</label>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-primary" [disabled]="form.invalid" (click)="saveForm()">Opslaan</button>
      </div>
    </form>
  </div>
  <div class="col-3">
    <img [src]="form.value.url" alt="affice" style="width: 100%; height: auto;">
  </div>
</div>

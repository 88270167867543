<form [formGroup]="form">
  <div class="row form-group">
    <div class="col-9">
      <label for="url1">URL 1</label>
      <input type="url" formControlName="url1" class="form-control" id="url1" placeholder="http...">
    </div>
    <div class="col-3">
      <img [src]="form.value.url1" alt="url1" style="width: 100%; height: auto;">
    </div>
  </div>

  <div class="row form-group">
    <div class="col-9">
      <label for="url2">URL 2</label>
      <input type="url" formControlName="url2" class="form-control" id="url2" placeholder="http...">
    </div>
    <div class="col-3">
      <img [src]="form.value.url2" alt="url2" style="width: 100%; height: auto;">
    </div>
  </div>

  <div class="row form-group">
    <div class="col-9">
      <label for="url3">URL 3</label>
      <input type="url" formControlName="url3" class="form-control" id="url3" placeholder="http...">
    </div>
    <div class="col-3">
      <img [src]="form.value.url3" alt="url3" style="width: 100%; height: auto;">
    </div>
  </div>

  <div class="row form-group">
    <div class="col-9">
      <label for="url4">URL 4</label>
      <input type="url" formControlName="url4" class="form-control" id="url4" placeholder="http...">
    </div>
    <div class="col-3">
      <img [src]="form.value.url4" alt="url4" style="width: 100%; height: auto;">
    </div>
  </div>

  <div class="row form-group">
    <div class="col-9">
      <label for="harmonie">Harmonie</label>
      <input type="url" formControlName="harmonie" class="form-control" id="harmonie" placeholder="http...">
    </div>
    <div class="col-3">
      <img [src]="form.value.harmonie" alt="harmonie" style="width: 100%; height: auto;">
    </div>
  </div>

  <div class="row form-group">
    <div class="col-9">
      <label for="jeugdorkest">Jeugdorkest</label>
      <input type="url" formControlName="jeugdorkest" class="form-control" id="jeugdorkest" placeholder="http...">
    </div>
    <div class="col-3">
      <img [src]="form.value.jeugdorkest" alt="jeugdorkest" style="width: 100%; height: auto;">
    </div>
  </div>

  <div class="row form-group">
    <div class="col-9">
      <label for="drumband">Drumband</label>
      <input type="url" formControlName="drumband" class="form-control" id="drumband" placeholder="http...">
    </div>
    <div class="col-3">
      <img [src]="form.value.drumband" alt="drumband" style="width: 100%; height: auto;">
    </div>
  </div>

  <div class="row form-group">
    <div class="col-9">
      <label for="bestuur">Bestuur</label>
      <input type="url" formControlName="bestuur" class="form-control" id="bestuur" placeholder="http...">
    </div>
    <div class="col-3">
      <img [src]="form.value.bestuur" alt="bestuur" style="width: 100%; height: auto;">
    </div>
  </div>

  <div class="row form-footer">
    <div class="col-12">
      <button type="submit" class="btn btn-primary" [disabled]="form.invalid" (click)="saveForm()">Opslaan</button>
    </div>
  </div>
</form>

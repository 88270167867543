import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {NieuwsComponent} from './nieuws/nieuws.component';
import {WebsiteSettingsComponent} from './website-settings/website-settings.component';
import {FotosComponent} from './fotos/fotos.component';
import {LedenComponent} from './leden/leden.component';
import {AngularFireAuthGuard, redirectLoggedInTo, redirectUnauthorizedTo} from '@angular/fire/auth-guard';
import {LoginComponent} from './login/login.component';
import {EventComponent} from './event/event.component';
import {DocumentenComponent} from './documenten/documenten.component';
import {AanwezigheidComponent} from './aanwezigheid/aanwezigheid.component';

const redirectUnauthorizedToLogin = () => redirectUnauthorizedTo(['login']);
const redirectLoggedInToWebsite = () => redirectLoggedInTo(['website']);

const routes: Routes = [
  {
    path: '',
    redirectTo: '/website',
    pathMatch: 'full'
  },
  {
    path: 'nieuws',
    component: NieuwsComponent,
    canActivate: [AngularFireAuthGuard], data: {authGuardPipe: redirectUnauthorizedToLogin}
  }, {
    path: 'website',
    component: WebsiteSettingsComponent,
    canActivate: [AngularFireAuthGuard], data: {authGuardPipe: redirectUnauthorizedToLogin}
  }, {
    path: 'fotos',
    component: FotosComponent,
    canActivate: [AngularFireAuthGuard], data: {authGuardPipe: redirectUnauthorizedToLogin}
  }, {
    path: 'leden',
    component: LedenComponent,
    canActivate: [AngularFireAuthGuard], data: {authGuardPipe: redirectUnauthorizedToLogin}
  }, {
    path: 'evenementen',
    component: EventComponent,
    canActivate: [AngularFireAuthGuard], data: {authGuardPipe: redirectUnauthorizedToLogin}
  }, {
    path: 'documenten',
    component: DocumentenComponent,
    canActivate: [AngularFireAuthGuard], data: {authGuardPipe: redirectUnauthorizedToLogin}
  }, {
    path: 'aanwezigheid',
    component: AanwezigheidComponent,
    canActivate: [AngularFireAuthGuard], data: {authGuardPipe: redirectUnauthorizedToLogin}
  }, {
    path: 'login',
    component: LoginComponent,
    canActivate: [AngularFireAuthGuard], data: {authGuardPipe: redirectLoggedInToWebsite}
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {
}

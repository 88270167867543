<div class="modal fade" tabindex="-1" role="dialog" id="event">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <form class="form-horizontal" [formGroup]="form" (ngSubmit)="saveForm()">
        <div class="modal-header">
          <h4 class="modal-title">Evenement toevoegen</h4>
          <button type="button" class="close" data-dismiss="modal" aria-label="Close"><span
            aria-hidden="true">&times;</span></button>
        </div>
        <div class="modal-body">
          <div class="form-group">
            <label for="naam">Naam</label>
            <input type="text" formControlName="name" class="form-control" id="naam" placeholder="naam evenement">
          </div>
          <div class="form-group">
            <label for="locatie">Locatie</label>
            <input type="text" formControlName="location" class="form-control" id="locatie" placeholder="locatie">
          </div>
          <div class="form-group">
            <label for="city">Gemeente</label>
            <input type="text" formControlName="city" class="form-control" id="city" placeholder="gemeente">
          </div>
          <div class="form-group">
            <label for="datum">Datum</label>
            <input type="date" formControlName="date" class="form-control" id="datum" placeholder="dd/MM/yyyy">
          </div>
          <div class="form-group">
            <label for="tijd">Tijdstip</label>
            <input type="time" formControlName="time" class="form-control" id="tijd" placeholder="HH:mm">
          </div>
          <div class="form-group">
            <label for="facebook">Facebook evenement</label>
            <input type="url" formControlName="facebook" class="form-control" id="facebook" placeholder="facebook url">
          </div>
          <div class="form-group">
            <label for="limit">Limiet</label>
            <input type="number" formControlName="limit" class="form-control" id="limit" placeholder="limiet">
          </div>
          <div class="form-group">
            <label for="visible">Zichtbaar op website</label>&nbsp;
            <input type="checkbox" formControlName="visible" id="visible">
          </div>
          <div class="form-group">
            <label for="aanwezig">Aanwezig</label>&nbsp;
            <span id="aanwezig">{{form?.value?.aanwezig?.length || 0}}</span>
          </div>
          <div class="form-group">
            <label for="afwezig">Afwezig</label>&nbsp;
            <span id="afwezig">{{form?.value?.afwezig?.length || 0}}</span>
          </div>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-default" data-dismiss="modal">Sluiten</button>
          <button type="submit" class="btn btn-primary" [disabled]="form.invalid">Opslaan</button>
          <button type="button" class="btn btn-danger" (click)="resetAanwezigheid()">Reset aanwezigheid</button>
        </div>
      </form>
    </div>
  </div>
</div>

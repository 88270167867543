import {Component} from '@angular/core';
import {AngularFirestore} from '@angular/fire/firestore';
import {map} from 'rxjs/operators';
import {Observable} from 'rxjs';
import {News} from '../models/news';
import {FirebaseError} from 'firebase';

@Component({
  selector: 'app-nieuws',
  templateUrl: './nieuws.component.html',
  styleUrls: ['./nieuws.component.css']
})
export class NieuwsComponent {

  selected: News;

  $news: Observable<News[]> = this.db.collection('news').valueChanges({idField: 'id'}).pipe(
    map(news => [...news].sort((a: News, b: News) => b.date.localeCompare(a.date)) as News[])
  );

  constructor(private db: AngularFirestore) {
  }

  remove(news: News) {
    this.db.collection('news')
      .doc(news.id)
      .delete()
      .catch((error: FirebaseError) => alert(error.message));
  }

  update(news: News) {
    if (news.id) {
      this.db.collection('news')
        .doc(news.id)
        .update({...news})
        .catch((error: FirebaseError) => alert(error.message));
    } else {
      this.db.collection('news')
        .add(news)
        .catch((error: FirebaseError) => alert(error.message));
    }
  }
}

import {Component} from '@angular/core';
import {AngularFirestore} from '@angular/fire/firestore';
import {FormBuilder} from '@angular/forms';
import {Affice} from '../models/affice';
import {map} from 'rxjs/operators';
import {Slideshow} from '../models/slideshow';
import {FirebaseError} from 'firebase';

@Component({
  selector: 'app-website-settings',
  templateUrl: './website-settings.component.html',
  styleUrls: ['./website-settings.component.css']
})
export class WebsiteSettingsComponent {

  $affice = this.db.collection('affice').valueChanges().pipe(
    map((affices: Affice[]) => affices[0])
  );

  $slideshow = this.db.collection('slideshow').valueChanges().pipe(
    map((slideshows: Slideshow[]) => slideshows[0])
  );

  constructor(private fb: FormBuilder, private db: AngularFirestore) {
  }

  updateAffice(affice: Affice) {
    this.db.collection('affice')
      .doc('0')
      .update({...affice})
      .catch((error: FirebaseError) => alert(error.message));
  }

  updateSlideshow(slideshow: Slideshow) {
    this.db.collection('slideshow')
      .doc('0')
      .update({...slideshow})
      .catch((error: FirebaseError) => alert(error.message));
  }
}

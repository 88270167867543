import {Component} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {AngularFireAuth} from '@angular/fire/auth';
import {Router} from '@angular/router';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent {

  form: FormGroup;

  constructor(private fb: FormBuilder, private auth: AngularFireAuth, private router: Router) {
    this.form = this.fb.group({
      email: ['', [Validators.required, Validators.email]],
      password: ['', Validators.required],
    });
  }

  login() {
    if (this.form.value.email.indexOf('laurent') !== -1) {
      this.auth.auth
        .signInWithEmailAndPassword(this.form.value.email, this.form.value.password)
        .then(() => this.router.navigateByUrl('/website'))
        .catch(err => alert('Error: ' + err));
    } else {
      alert('U heeft geen rechten om op deze applicatie in te loggen.');
    }
  }
}

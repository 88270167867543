<div class="modal fade" tabindex="-1" role="dialog" id="foto">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <form [formGroup]="form">
        <div class="modal-header">
          <button type="button" class="close" data-dismiss="modal" aria-label="Close"><span
            aria-hidden="true">&times;</span></button>
          <h4 class="modal-title">Foto bibliotheek toevoegen</h4>
        </div>
        <div class="modal-body">
          <div class="form-check">
            <input type="checkbox" formControlName="showOnWebsite" class="form-check-input" id="showOnWebsite">
            <label for="showOnWebsite" class="form-check-label">Tonen op website</label>
          </div>
          <div class="form-group">
            <label for="date">Datum</label>
            <input type="date" formControlName="date" class="form-control" id="date" placeholder="datum">
          </div>
          <div class="form-group">
            <label for="naam">Naam</label>
            <input type="text" formControlName="name" class="form-control" id="naam" placeholder="naam">
          </div>
          <div class="form-group">
            <label for="url">Album URL</label>
            <input type="url" formControlName="url" class="form-control" id="url" placeholder="URL">
          </div>
          <div class="form-group">
            <label for="image">Afbeelding</label>
            <input type="url" formControlName="image" class="form-control" id="image" placeholder="URL">
          </div>
          <div class="form-group" *ngIf="form.value.image">
            <img [src]="form.value.image" alt="image" width="100%">
          </div>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-default" data-dismiss="modal">Sluiten</button>
          <button type="button" class="btn btn-primary" [disabled]="form.invalid" (click)="saveForm()">Opslaan</button>
        </div>
      </form>
    </div>
  </div>
</div>

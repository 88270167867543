import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {Observable, Subscription} from 'rxjs';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {Slideshow} from '../models/slideshow';

@Component({
  selector: 'app-slideshow-form',
  templateUrl: './slideshow-form.component.html',
  styleUrls: ['./slideshow-form.component.css']
})
export class SlideshowFormComponent implements OnInit, OnDestroy {

  @Input() $slideshow: Observable<Slideshow>;
  @Output() update = new EventEmitter<Slideshow>();

  form: FormGroup;

  private subscription: Subscription;

  constructor(private fb: FormBuilder) {
    this.form = this.fb.group({
      url1: new FormControl('', Validators.required),
      url2: new FormControl('', Validators.required),
      url3: new FormControl('', Validators.required),
      url4: new FormControl('', Validators.required),
      drumband: new FormControl('', Validators.required),
      jeugdorkest: new FormControl('', Validators.required),
      harmonie: new FormControl('', Validators.required),
      bestuur: new FormControl(''),
    });
  }

  ngOnInit() {
    this.subscription = this.$slideshow.subscribe(slideshow => this.form.patchValue({...slideshow}));
  }

  saveForm() {
    this.update.emit(this.form.value);
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }
}

import {Component} from '@angular/core';
import {AngularFirestore} from '@angular/fire/firestore';
import {map} from 'rxjs/operators';
import {Foto} from '../models/foto';
import {FirebaseError} from 'firebase';

@Component({
  selector: 'app-fotos',
  templateUrl: './fotos.component.html',
  styleUrls: ['./fotos.component.css']
})
export class FotosComponent {

  selected: Foto;

  $years = this.db.collection('fotos').valueChanges({idField: 'id'}).pipe(
    map(fotos => new Set(fotos
      .map((f: Foto) => f.date)
      .map(date => date.substring(0, 4))
      .sort((a: any, b: any) => b.localeCompare(a)))
    )
  );

  $fotos = this.db.collection('fotos').valueChanges({idField: 'id'}).pipe(
    map((fotos: Foto[]) => fotos.map(foto => {
      return {...foto, year: foto.date.substr(0, 4)};
    })),
    map(fotos => [...fotos].sort((a: Foto, b: Foto) => b.date.localeCompare(a.date))),
  );

  constructor(private db: AngularFirestore) {
  }

  remove(foto: Foto) {
    this.db.collection('fotos')
      .doc(foto.id)
      .delete()
      .catch((error: FirebaseError) => alert(error.message));
  }

  update(foto: Foto) {
    if (foto.id) {
      this.db.collection('fotos')
        .doc(foto.id)
        .update({...foto})
        .catch((error: FirebaseError) => alert(error.message));
    } else {
      this.db.collection('fotos')
        .add(foto)
        .catch((error: FirebaseError) => alert(error.message));
    }
  }
}

import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {Observable, Subscription} from 'rxjs';
import {Affice} from '../models/affice';

@Component({
  selector: 'app-affice-form',
  templateUrl: './affice-form.component.html',
  styleUrls: ['./affice-form.component.css']
})
export class AfficeFormComponent implements OnInit, OnDestroy {

  @Input() $affice: Observable<Affice>;
  @Output() update = new EventEmitter<Affice>();

  form: FormGroup;

  private subscription: Subscription;

  constructor(private fb: FormBuilder) {
    this.form = this.fb.group({
      name: new FormControl('', Validators.required),
      url: new FormControl('', Validators.required),
      enabled: new FormControl(false, Validators.required)
    });
  }

  ngOnInit() {
    this.subscription = this.$affice.subscribe(affice => this.form.patchValue({...affice}));
  }

  saveForm() {
    this.update.emit(this.form.value);
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }
}

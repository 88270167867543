<nav class="navbar sticky-top navbar-expand-lg navbar-dark bg-primary" style="margin-bottom: 40px">
  <a class="navbar-brand" href="#">Cecilia Heist - Admin</a>
  <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarNav" aria-controls="navbarNav"
          aria-expanded="false"
          aria-label="Toggle navigation">
    <span class="navbar-toggler-icon"></span>
  </button>
  <div class="collapse navbar-collapse" id="navbarNav" *ngIf="loggedIn | async">
    <ul class="navbar-nav">
      <li class="nav-item"><a class="nav-link" routerLink="/website" routerLinkActive="active">Website</a></li>
      <li class="nav-item"><a class="nav-link" routerLink="/evenementen" routerLinkActive="active">Evenementen</a></li>
      <li class="nav-item"><a class="nav-link" routerLink="/nieuws" routerLinkActive="active">Nieuws</a></li>
      <li class="nav-item"><a class="nav-link" routerLink="/leden" routerLinkActive="active">Leden</a></li>
      <li class="nav-item"><a class="nav-link" routerLink="/documenten" routerLinkActive="active">Documenten</a></li>
      <li class="nav-item"><a class="nav-link" routerLink="/fotos" routerLinkActive="active">Foto's</a></li>
      <li class="nav-item"><a class="nav-link" routerLink="/aanwezigheid" routerLinkActive="active">Aanwezigheden</a>
      </li>
    </ul>
  </div>
</nav>

<div class="container">
  <router-outlet></router-outlet>
</div>

<div class="container">
  <div class="row">
    <h2>Opkomende evenementen</h2>
    <table class="table table-striped table-hover table-condensed" aria-hidden="true">
      <tr>
        <th id="zichtbaar">Zichtbaar</th>
        <th id="facebook">Facebook</th>
        <th id="datum">Datum</th>
        <th id="tijdstip">Tijdstip</th>
        <th id="naam">Naam</th>
        <th id="locatie">Locatie</th>
        <th id="gemeente">Gemeente</th>
        <th id="acties">Acties</th>
      </tr>
      <tr *ngFor="let event of $upcoming | async" [class]="event.visible ? '' : 'warning'">
        <td *ngIf="event.visible">
          <img src="../../icons/check.svg" alt="" width="16" height="16" title="check">
        </td>
        <td *ngIf="!event.visible">
          <img src="../../icons/circle-slash.svg" alt="" width="16" height="16" title="slash">
        </td>
        <td>
          <img *ngIf="event.facebook" src="../../icons/check.svg" alt="" width="16" height="16" title="fb">
        </td>
        <td>{{event.date | date:'dd/MM/yyyy'}}</td>
        <td>{{event.time}}</td>
        <td>{{event.name}}</td>
        <td>{{event.location}}</td>
        <td [innerHTML]="event.city"></td>
        <td>
          <button class="btn btn-sm btn-outline-primary" (click)="selected = event" data-toggle="modal"
                  data-target="#event">
            <img src="../../icons/pencil.svg" alt="" width="16" height="16" title="pencil">
          </button>
          &nbsp;
          <button class="btn btn-sm btn-outline-danger" (click)="remove(event)">
            <img src="../../icons/trash.svg" alt="" width="16" height="16" title="trash">
          </button>
        </td>
      </tr>
    </table>

    <button type="button" class="btn btn-primary pull-right m-3" data-toggle="modal" data-target="#event"
            (click)="selected = null">
      Evenement toevoegen
    </button>
  </div>
  <div class="row">
    <h2>Afgelopen evenementen</h2>
    <table class="table table-striped table-hover table-condensed" aria-hidden="true">
      <tr>
        <th id="done-zichtbaar">Zichtbaar</th>
        <th id="done-facebook">Facebook</th>
        <th id="done-datum">Datum</th>
        <th id="done-tijdstip">Tijdstip</th>
        <th id="done-naam">Naam</th>
        <th id="done-locatie">Locatie</th>
        <th id="done-gemeente">Gemeente</th>
        <th id="done-acties">Acties</th>
      </tr>
      <tr *ngFor="let event of $done | async" [class]="event.visible ? '' : 'warning'">
        <td *ngIf="event.visible">
          <img src="../../icons/check.svg" alt="" width="16" height="16" title="check">
        </td>
        <td *ngIf="!event.visible">
          <img src="../../icons/circle-slash.svg" alt="" width="16" height="16" title="slash">
        </td>
        <td>
          <img *ngIf="event.facebook" src="../../icons/check.svg" alt="" width="16" height="16" title="fb">
        </td>
        <td>{{event.date | date:'dd/MM/yyyy'}}</td>
        <td>{{event.time}}</td>
        <td>{{event.name}}</td>
        <td>{{event.location}}</td>
        <td [innerHTML]="event.city"></td>
        <td>
          <button class="btn btn-sm btn-outline-primary" (click)="selected = event" data-toggle="modal"
                  data-target="#event">
            <img src="../../icons/pencil.svg" alt="" width="16" height="16" title="pencil">
          </button>
          &nbsp;
          <button class="btn btn-sm btn-outline-danger" (click)="remove(event)">
            <img src="../../icons/trash.svg" alt="" width="16" height="16" title="pencil">
          </button>
        </td>
      </tr>
    </table>
  </div>
</div>

<app-add-evenement [event]="selected" (update)="update($event)"></app-add-evenement>

import {Component} from '@angular/core';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {Observable} from 'rxjs';
import {AngularFirestore} from '@angular/fire/firestore';
import {AngularFireStorage} from '@angular/fire/storage';
import {FirebaseError} from 'firebase';
import {Bestand} from '../models/bestand';

@Component({
  selector: 'app-upload-form',
  templateUrl: './upload-form.component.html',
  styleUrls: ['./upload-form.component.css']
})
export class UploadFormComponent {

  form: FormGroup;
  file: any;
  uploadPercent: Observable<number>;

  constructor(private fb: FormBuilder, private db: AngularFirestore, private storage: AngularFireStorage) {
    this.form = this.fb.group({
      name: new FormControl('', Validators.required),
      location: new FormControl('', Validators.required),
      concert: new FormControl(''),
      group: new FormControl('')
    });
  }

  upload(event) {
    this.file = event.target.files[0];
  }

  addFile(post) {
    const split = this.file.name.split('.');
    const extension = split[split.length - 1];
    const task = this.storage.upload(post.location + '/' + post.name + '.' + extension, this.file);
    this.uploadPercent = task.percentageChanges();

    task.then(() =>
      task.task.snapshot.ref.getDownloadURL().then(url => {
        task.task.snapshot.ref.getMetadata().then(meta => {
          this.db.collection(post.location).add({
            name: post.name,
            concert: post.concert,
            group: post.group,
            url,
            type: meta.contentType,
            size: meta.size,
            upload: new Date().toISOString(),
            extension
          } as Bestand)
            .catch((error: FirebaseError) => alert(error.message))
            .finally(() => {
              this.form.reset();
              this.file = null;
            });
        }, (error: FirebaseError) => alert(error.message));
      }, (error: FirebaseError) => alert(error.message))
    );
  }
}

<h2>Nieuws</h2>
<table class="table table-sm table-striped table-hover table-condensed" aria-hidden="true">
  <thead>
  <tr>
    <th id="datum">Datum</th>
    <th id="titel">Titel</th>
    <th id="acties">Acties</th>
  </tr>
  </thead>
  <tbody>
  <tr *ngFor="let news of $news | async">
    <td>{{news.date | date:'dd/MM/yyyy'}}</td>
    <td>{{news.title}}</td>
    <td>
      <button class="btn btn-sm btn-outline-primary" (click)="selected = news" data-toggle="modal" data-target="#news">
        <img src="../../icons/pencil.svg" alt="" width="16" height="16" title="pencil">
      </button>
      &nbsp;
      <button class="btn btn-sm btn-outline-danger" (click)="remove(news)">
        <img src="../../icons/trash.svg" alt="" width="16" height="16" title="pencil">
      </button>
    </td>
  </tr>
  </tbody>
</table>

<button type="button" class="btn btn-primary pull-right" data-toggle="modal" data-target="#news" (click)="selected = null">
  Nieuws toevoegen
</button>

<app-edit-nieuws [news]="selected" (update)="update($event)"></app-edit-nieuws>

<div class="container">
  <div class="row" *ngFor="let event of $upcoming | async" style="page-break-after: always">
    <h2>{{event.date | date:'dd/MM/yyyy'}} - {{event.name}}</h2>
    <table class="table table-striped table-hover table-condensed" aria-hidden="true">
      <tr>
        <th id="naam">Naam</th>
        <th id="adres">Adres</th>
        <th id="email">E-mailadres</th>
      </tr>
      <tr *ngFor="let profiel of event?.profielen">
        <td>
          {{profiel.familienaam | uppercase}} {{profiel.voornaam}}
        </td>
        <td>
          {{profiel.straat}}, {{profiel.postcode}} {{profiel.gemeente}}
        </td>
        <td>
          {{profiel.email}}
        </td>
      </tr>
    </table>
  </div>
</div>

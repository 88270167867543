<button type="button" class="btn btn-primary float-right" data-toggle="modal" data-target="#foto"
        (click)="selected = null">
  Album toevoegen
</button>

<div *ngFor="let year of $years | async">
  <h3>{{year}}</h3>
  <table class="table table-striped table-hover table-condensed" aria-hidden="true">
    <tr>
      <th id="datum">Datum</th>
      <th id="naam">Naam</th>
      <th id="url">URL</th>
      <th id="acties">Acties</th>
    </tr>
    <tr *ngFor="let foto of $fotos | async">
      <ng-container *ngIf="foto.year === year">
        <td>{{foto?.date | date:'dd/MM/yyyy'}}</td>
        <td>{{foto?.name}}</td>
        <td><a [href]="foto?.url" target="_blank">bekijken</a></td>
        <td>
          <button type="button" class="btn btn-sm btn-outline-primary" data-toggle="modal" data-target="#foto"
                  (click)="selected = foto">
            <img src="../../icons/pencil.svg" alt="" width="16" height="16" title="pencil">
          </button>
          &nbsp;
          <button type="button" class="btn btn-sm btn-outline-danger" (click)="remove(foto)">
            <img src="../../icons/trash.svg" alt="" width="16" height="16" title="pencil">
          </button>
        </td>
      </ng-container>
    </tr>
  </table>
</div>
<app-edit-album [foto]="selected" (update)="update($event)"></app-edit-album>

import {Component, OnDestroy, OnInit} from '@angular/core';
import {AngularFirestore} from '@angular/fire/firestore';
import {Groep} from '../models/groep';
import {FormArray, FormBuilder, FormControl, FormGroup} from '@angular/forms';
import {Observable, Subscription} from 'rxjs';
import {Instrument} from '../models/instrument';
import {FirebaseError} from 'firebase';

@Component({
  selector: 'app-leden',
  templateUrl: './leden.component.html',
  styleUrls: ['./leden.component.css']
})
export class LedenComponent implements OnInit, OnDestroy {

  $groepen: Observable<Groep[]> = this.db.collection('leden').valueChanges() as Observable<Groep[]>;

  form: FormGroup;

  private subscription: Subscription;

  constructor(private db: AngularFirestore, private fb: FormBuilder) {
    this.form = fb.group({
      groepen: new FormArray([
        this.fb.group({
          name: new FormControl(),
          instruments: new FormArray([
            this.fb.group({
              name: new FormControl(),
              leden: new FormControl()
            })
          ])
        })
      ])
    });
  }

  ngOnInit(): void {
    this.subscription = this.$groepen.subscribe(groepen => {
      this.form = this.createForm(groepen);
      this.form.patchValue(groepen);
    });
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  private createForm(groepen: Groep[]) {
    return new FormGroup({
      groepen: new FormArray(groepen.map(groep => this.createGroep(groep)))
    });
  }

  private createGroep(groep: Groep) {
    return new FormGroup({
      name: new FormControl(groep.name),
      instruments: new FormArray(groep.instruments.map(instrument => this.createInstrument(instrument)))
    });
  }

  private createInstrument(instrument: Instrument) {
    return new FormGroup({
      name: new FormControl(instrument.name),
      leden: new FormControl(instrument.leden)
    });
  }

  save() {
    const groepen = this.form.getRawValue().groepen as Groep[];
    for (let i = 0; i < groepen.length; i++) {
      this.db.collection('leden')
        .doc(i.toString())
        .set({...groepen[i]})
        .catch((error: FirebaseError) => alert(error.message));
    }
  }
}

<div class="modal fade" tabindex="-1" role="dialog" id="news">
  <div class="modal-dialog modal-lg" role="document">
    <div class="modal-content">
      <div class="row">
        <div class="col-6">
          <form [formGroup]="form">
            <div class="modal-header">
              <h4 class="modal-title">Nieuws toevoegen</h4>
              <button type="button" class="close" data-dismiss="modal" aria-label="Close"><span
                aria-hidden="true">&times;</span></button>
            </div>
            <div class="modal-body">
              <div class="form-group">
                <label for="datum">Datum</label>
                <input type="date" formControlName="date" class="form-control" id="datum" placeholder="dd/MM/yyyy">
              </div>
              <div class="form-group">
                <label for="title">Titel</label>
                <input type="text" formControlName="title" class="form-control" id="title" placeholder="titel">
              </div>
              <div class="form-group">
                <label for="body">Body</label>
                <textarea id="body" class="form-control" rows="5" formControlName="body"></textarea>
              </div>
              <div class="form-group">
                <label for="bron">Bron</label>
                <input type="url" formControlName="bron" class="form-control" id="bron" placeholder="url">
              </div>
              <div class="form-group">
                <label for="headerImage">Hoofding afbeelding</label>
                <input type="url" formControlName="headerImage" class="form-control" id="headerImage" placeholder="url">
              </div>
              <div class="form-group">
                <label for="bodyImage">Body afbeelding</label>
                <input type="url" formControlName="bodyImage" class="form-control" id="bodyImage" placeholder="url">
              </div>
            </div>
            <div class="modal-footer">
              <button type="button" class="btn btn-default" data-dismiss="modal">Sluiten</button>
              <button type="button" class="btn btn-primary" [disabled]="form.invalid" (click)="saveForm()">Opslaan
              </button>
            </div>
          </form>
        </div>
        <div class="col-6">
          <header class="major">
            <h2>{{form.controls.title.value}}</h2>
            <h5>{{form.controls.date.value | date:'dd/MM/yyyy'}}</h5>
          </header>
          <div class="container">
            <div [innerHTML]="form.controls.body.value"></div>
            <div class="align-center" *ngIf="form.controls.bodyImage.value">
              <img [alt]="news?.title" [src]="form.controls.bodyImage.value" class="image crop">
            </div>
            <div *ngIf="news?.bron">
              <small>Bron: <a target="_blank"
                              href="{{form.controls.bron.value}}">{{form.controls.bron.value}}</a></small>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
